import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const InternalMenuItem = ({ path, currentPathName, title }) => (
  <li>
    <Link to={path} className={currentPathName === path ? "is-active" : ""}>
      {title}
    </Link>
  </li>
)

const Menu = ({ pathname }) => (
  <aside className="menu">
    <p className="menu-label">Cases</p>
    <ul className="menu-list">
      <InternalMenuItem
        path="/"
        currentPathName={pathname}
        title="UK, nations and regions"
      />
      <InternalMenuItem
        path="/utla"
        currentPathName={pathname}
        title="Upper tier local authorities"
      />
      <InternalMenuItem
        path="/ltla"
        currentPathName={pathname}
        title="Lower tier local authorities"
      />
      <InternalMenuItem
        path="/case-map"
        currentPathName={pathname}
        title="Map"
      />
    </ul>
    <p className="menu-label">New hospital admissions</p>
    <ul className="menu-list">
      <InternalMenuItem
        path="/admissions"
        currentPathName={pathname}
        title="Nations and NHS regions"
      />
      <InternalMenuItem
        path="/admissions-map"
        currentPathName={pathname}
        title="England NHS region map"
      />
    </ul>
    <p className="menu-label">Patients in hospital</p>
    <ul className="menu-list">
      <InternalMenuItem
        path="/patients"
        currentPathName={pathname}
        title="Nations and NHS regions"
      />
      <InternalMenuItem
        path="/patients-map"
        currentPathName={pathname}
        title="England NHS region map"
      />
    </ul>
  </aside>
)

Menu.propTypes = {
  pathname: PropTypes.string.isRequired,
}

export default Menu
