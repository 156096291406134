import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import React from "react"

export const PercentageChange = ({ value }) => {
  if (Number.isNaN(value)) return <></>

  if (!Number.isFinite(value)) return "n/a"

  let displayValue = Math.abs(value * 100)

  return (
    <>
      {value > 0 && (
        <FontAwesomeIcon className="mr-1 has-text-danger" icon={faArrowUp} />
      )}
      {value < 0 && (
        <FontAwesomeIcon className="mr-1 has-text-success" icon={faArrowDown} />
      )}
      {displayValue.toFixed(displayValue < 100 && displayValue !== 0 ? 1 : 0)}%
    </>
  )
}
